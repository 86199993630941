var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		pa5: _vm.$vuetify.breakpoint.smAndDown,
		palr5: _vm.$vuetify.breakpoint.mdAndUp,
	}},[_c('div',[_c('v-col',{staticClass:"text-left",attrs:{"offset-md":"2","md":"8","offset-lg":"3","lg":"6","sm":"12","cols":"12"}},[_c('h2',[_vm._v("Session "+_vm._s(_vm.exerciceInfos.position_list)+"/"+_vm._s(_vm.allExerciceFromFormationCategory.nb_exos_displayed))])]),_c('v-row',[_c('v-col',{attrs:{"offset-md":"2","md":"8","offset-lg":"3","lg":"6","sm":"12","cols":"12"}},[(_vm.lastExerciceDone)?_c('div',[(_vm.lastExerciceDone.score < 0.8)?_c('div',[(_vm.lastExerciceDone.score > 0)?_c('v-alert',{attrs:{"prominent":"","text":"","type":"info","color":"#4e52ba"}},[_c('strong',[_vm._v("Meilleur score = "+_vm._s(Math.ceil(_vm.lastExerciceDone.score * 100))+"%, tu peux mieux faire !")])]):_vm._e(),_c('v-alert',{attrs:{"prominent":"","color":"#2A3B4D","dark":"","icon":"mdi-target"}},[_c('strong',[_vm._v("Obtiens 80% de bonnes réponses sur les quiz pour débloquer la session suivante ! ")])])],1):(_vm.lastExerciceDone.score == 1)?_c('div',[_c('v-alert',{attrs:{"prominent":"","type":"success","color":"#FFD15E"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.lastExerciceDone.score * 100))+"% de bonnes réponses, c'est un parfait !")])])],1):_c('div',[_c('v-alert',{attrs:{"prominent":"","type":"success","color":"#2dc7ae"}},[_c('strong',[_vm._v("Bravo ! Session validée avec "+_vm._s(Math.ceil(_vm.lastExerciceDone.score * 100))+"% de bonnes réponses")])])],1)]):_c('div',[_c('v-alert',{attrs:{"prominent":"","color":"#2A3B4D","dark":"","icon":"mdi-target"}},[_c('strong',[_vm._v("Obtiens 80% de bonnes réponses sur les quiz pour débloquer la session suivante ! ")])])],1),_c('div',{staticClass:"text-center",class:{
						'button-floating': _vm.$vuetify.breakpoint.smAndDown,
					}},[(_vm.lastExerciceDone)?_c('div',[(_vm.lastExerciceDone.score < 0.8)?_c('div',[_c('v-btn',{staticClass:"hvr-grow",attrs:{"color":"black","large":"","dark":""},on:{"click":function($event){return _vm.goToTrainingQuestion()}}},[_c('strong',[_vm._v("Faire mieux")]),_c('v-icon',{staticClass:"ortho-icon-white fs15 ml5"},[_vm._v("fas fa-arrow-right")])],1)],1):_c('div',[_c('v-btn',{staticClass:"hvr-grow",attrs:{"color":"black","large":"","dark":""},on:{"click":function($event){return _vm.goToTrainingQuestion()}}},[_c('strong',[_vm._v("Refaire")]),_c('v-icon',{staticClass:"ortho-icon-white fs15 ml5"},[_vm._v("fas fa-arrow-right")])],1)],1)]):_c('div',[_c('v-btn',{staticClass:"hvr-grow",attrs:{"color":"black","large":"","dark":""},on:{"click":function($event){return _vm.goToTrainingQuestion()}}},[_c('strong',[_vm._v("C'est parti !")]),_c('v-icon',{staticClass:"ortho-icon-white fs15 ml5"},[_vm._v("fas fa-arrow-right")])],1)],1)]),_c('v-card',{staticClass:"mt20 shadow-blue",class:{
						'pa-3 ortho-align-center': _vm.$vuetify.breakpoint.smAndDown,
						pa30: _vm.$vuetify.breakpoint.mdAndUp,
					}},[_c('h2',{staticClass:"mb-1 text-left"},[_vm._v("Ta performance")]),_c('p',{staticClass:"ortho-text-gray text-left"},[_vm._v("Relatif au dernier essai")]),(_vm.performanceExercice.length > 0)?_c('div',_vm._l((_vm.performanceExercice),function(score,index){return _c('div',{key:index,staticClass:"d-flex justify-space-between align-center pt-3 pb-3 border-section-score"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('p',{staticClass:"mb-0 text-left"},[_c('span',{class:{ 'ortho-display-none': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v("👉")]),_vm._v(" "+_vm._s(score.story_name)+" ")]):_c('p',{staticClass:"mb-0 text-left"},[_c('span',{class:{ 'ortho-display-none': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v("👉")]),_vm._v(" "+_vm._s(_vm._f("tronc")(score.story_name))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 ortho-text-green-flash mr-4",class:{
										greenText: score.scoreTotal * 10 < 50,
										goldText: score.scoreTotal * 10 == 50,
									}},[_c('strong',{directives:[{name:"show",rawName:"v-show",value:(_vm.timeoutCircleProgress == false),expression:"timeoutCircleProgress == false"}]},[_vm._v(_vm._s(score.scoreTotal * 10))]),_c('strong',[(score.scoreTotal * 10 < 50)?_c('img',{staticClass:"lighteningIcons",attrs:{"src":require("@/assets/icons/lightening-green.svg")}}):_c('img',{staticClass:"lighteningIcons",attrs:{"src":require("@/assets/icons/lightening-gold.svg")}}),_vm._v(" / "+_vm._s(score.lengthQs * 10))])]),_c('v-progress-circular',{attrs:{"rotate":"270","size":"40","width":"7","indeterminate":_vm.timeoutCircleProgress,"value":(score.scoreTotal / score.lengthQs) * 100,"color":_vm.colorRules(score.scoreTotal / score.lengthQs)}})],1)])}),0):_c('div',_vm._l((_vm.exerciceInfos.story_id),function(score,index){return _c('div',{key:index,staticClass:"d-flex justify-space-between align-center pt-3 pb-3 border-section-score"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('p',{staticClass:"mb-0 text-left"},[_c('span',{class:{ 'ortho-display-none': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v("👉")]),_vm._v(" "+_vm._s(score.rule_tags_id.name)+" ")]):_c('p',{staticClass:"mb-0 text-left"},[_c('span',{class:{ 'ortho-display-none': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v("👉")]),_vm._v(" "+_vm._s(_vm._f("tronc")(score.rule_tags_id.name))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 color-wait mr-4"},[_vm._v("En attente")]),_c('v-progress-circular',{attrs:{"rotate":"90","size":"40","width":"7","indeterminate":_vm.timeoutCircleProgress,"value":0,"color":"#dcdcdc"}})],1)])}),0)]),_c('v-card',{staticClass:"mt20 shadow-blue",class:{
						'pa-3': _vm.$vuetify.breakpoint.smAndDown,
						pa30: _vm.$vuetify.breakpoint.mdAndUp,
					}},[_c('h2',{staticClass:"mb20"},[_vm._v("Bénéfices")]),_c('v-row',[_c('v-col',{attrs:{"md":"1","sm":"12","cols":"12"}},[_c('img',{staticClass:"pointIcon",attrs:{"src":require("@/assets/icons/memoryIcon.png")}})]),_c('v-col',{staticClass:"textPointIcon",attrs:{"md":"10","sm":"12","cols":"12"}},[_c('h3',[_vm._v("Améliore ta mémoire")]),_c('p',[_vm._v(" Répétition des règles déjà maîtrisées pour s’assurer que tu les as acquises. Travailler la répétition permet d’élever sa mémoire orthographique. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"1","sm":"12","cols":"12"}},[_c('img',{staticClass:"pointIcon",attrs:{"src":require("@/assets/icons/masteryIcon.png")}})]),_c('v-col',{staticClass:"textPointIcon",attrs:{"md":"10","sm":"12","cols":"12"}},[_c('h3',[_vm._v("Affine ta maîtrise")]),_c('p',[_vm._v(" 3 règles à découvrir : découvre les astuces pour ne plus jamais les oublier et entraîne-toi sur ces règles pour être performant à l’écrit. ")])])],1)],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}})],1)],1),_c('Loading',{attrs:{"show":_vm.loading_show,"label":"On commence par la mémoire"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }