<template>
	<div
		:class="{
			pa5: $vuetify.breakpoint.smAndDown,
			palr5: $vuetify.breakpoint.mdAndUp,
		}"
	>
		<div>
			<v-col offset-md="2" md="8" offset-lg="3" lg="6" sm="12" cols="12" class="text-left">
				<h2>Session {{ exerciceInfos.position_list }}/{{ allExerciceFromFormationCategory.nb_exos_displayed }}</h2>
			</v-col>
			<v-row>
				<v-col offset-md="2" md="8" offset-lg="3" lg="6" sm="12" cols="12">
					<div v-if="lastExerciceDone">
						<div v-if="lastExerciceDone.score < 0.8">
							<v-alert prominent v-if="lastExerciceDone.score > 0" text type="info" color="#4e52ba">
								<strong>Meilleur score = {{ Math.ceil(lastExerciceDone.score * 100) }}%, tu peux mieux faire !</strong>
							</v-alert>
							<v-alert prominent color="#2A3B4D" dark icon="mdi-target">
								<strong>Obtiens 80% de bonnes réponses sur les quiz pour débloquer la session suivante ! </strong>
							</v-alert>
						</div>
						<div v-else-if="lastExerciceDone.score == 1">
							<v-alert prominent type="success" color="#FFD15E">
								<strong>{{ Math.ceil(lastExerciceDone.score * 100) }}% de bonnes réponses, c'est un parfait !</strong>
							</v-alert>
						</div>
						<div v-else>
							<v-alert prominent type="success" color="#2dc7ae">
								<strong
									>Bravo ! Session validée avec {{ Math.ceil(lastExerciceDone.score * 100) }}% de bonnes
									réponses</strong
								>
							</v-alert>
						</div>
					</div>
					<div v-else>
						<v-alert prominent color="#2A3B4D" dark icon="mdi-target">
							<strong>Obtiens 80% de bonnes réponses sur les quiz pour débloquer la session suivante ! </strong>
						</v-alert>
					</div>
					<div
						class="text-center"
						:class="{
							'button-floating': $vuetify.breakpoint.smAndDown,
						}"
					>
						<div v-if="lastExerciceDone">
							<div v-if="lastExerciceDone.score < 0.8">
								<v-btn color="black" large dark class="hvr-grow" @click="goToTrainingQuestion()">
									<strong>Faire mieux</strong>
									<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
								</v-btn>
							</div>
							<div v-else>
								<v-btn color="black" large dark class="hvr-grow" @click="goToTrainingQuestion()">
									<strong>Refaire</strong>
									<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
								</v-btn>
							</div>
						</div>
						<div v-else>
							<v-btn color="black" large dark class="hvr-grow" @click="goToTrainingQuestion()">
								<strong>C'est parti !</strong>
								<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
							</v-btn>
						</div>
					</div>
					<v-card
						class="mt20 shadow-blue"
						:class="{
							'pa-3 ortho-align-center': $vuetify.breakpoint.smAndDown,
							pa30: $vuetify.breakpoint.mdAndUp,
						}"
					>
						<h2 class="mb-1 text-left">Ta performance</h2>
						<p class="ortho-text-gray text-left">Relatif au dernier essai</p>
						<div v-if="performanceExercice.length > 0">
							<div
								v-for="(score, index) in performanceExercice"
								:key="index"
								class="d-flex justify-space-between align-center pt-3 pb-3 border-section-score"
							>
								<p v-if="$vuetify.breakpoint.mdAndUp" class="mb-0 text-left">
									<span :class="{ 'ortho-display-none': $vuetify.breakpoint.smAndDown }">👉</span>
									{{ score.story_name }}
								</p>
								<p v-else class="mb-0 text-left">
									<span :class="{ 'ortho-display-none': $vuetify.breakpoint.smAndDown }">👉</span>
									{{ score.story_name | tronc }}
								</p>

								<div class="d-flex align-center">
									<p
										class="mb-0 ortho-text-green-flash mr-4"
										:class="{
											greenText: score.scoreTotal * 10 < 50,
											goldText: score.scoreTotal * 10 == 50,
										}"
									>
										<strong v-show="timeoutCircleProgress == false">{{ score.scoreTotal * 10 }}</strong>
										<strong>
											<img
												class="lighteningIcons"
												v-if="score.scoreTotal * 10 < 50"
												src="@/assets/icons/lightening-green.svg"
											/>
											<img class="lighteningIcons" v-else src="@/assets/icons/lightening-gold.svg" />

											/

											{{ score.lengthQs * 10 }}</strong
										>
									</p>
									<v-progress-circular
										rotate="270"
										size="40"
										width="7"
										:indeterminate="timeoutCircleProgress"
										:value="(score.scoreTotal / score.lengthQs) * 100"
										:color="colorRules(score.scoreTotal / score.lengthQs)"
									>
									</v-progress-circular>
								</div>
							</div>
						</div>
						<div v-else>
							<div
								v-for="(score, index) in exerciceInfos.story_id"
								:key="index"
								class="d-flex justify-space-between align-center pt-3 pb-3 border-section-score"
							>
								<p v-if="$vuetify.breakpoint.mdAndUp" class="mb-0 text-left">
									<span :class="{ 'ortho-display-none': $vuetify.breakpoint.smAndDown }">👉</span>
									{{ score.rule_tags_id.name }}
								</p>
								<p v-else class="mb-0 text-left">
									<span :class="{ 'ortho-display-none': $vuetify.breakpoint.smAndDown }">👉</span>
									{{ score.rule_tags_id.name | tronc }}
								</p>

								<div class="d-flex align-center">
									<p class="mb-0 color-wait mr-4">En attente</p>
									<v-progress-circular
										rotate="90"
										size="40"
										width="7"
										:indeterminate="timeoutCircleProgress"
										:value="0"
										color="#dcdcdc"
									>
									</v-progress-circular>
								</div>
							</div>
						</div>
					</v-card>
					<v-card
						class="mt20 shadow-blue"
						:class="{
							'pa-3': $vuetify.breakpoint.smAndDown,
							pa30: $vuetify.breakpoint.mdAndUp,
						}"
					>
						<h2 class="mb20">Bénéfices</h2>
						<v-row>
							<v-col md="1" sm="12" cols="12">
								<img class="pointIcon" src="@/assets/icons/memoryIcon.png" />
							</v-col>
							<v-col md="10" sm="12" cols="12" class="textPointIcon">
								<h3>Améliore ta mémoire</h3>
								<p>
									Répétition des règles déjà maîtrisées pour s’assurer que tu les as acquises. Travailler la répétition
									permet d’élever sa mémoire orthographique.
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="1" sm="12" cols="12">
								<img class="pointIcon" src="@/assets/icons/masteryIcon.png" />
							</v-col>
							<v-col md="10" sm="12" cols="12" class="textPointIcon">
								<h3>Affine ta maîtrise</h3>
								<p>
									3 règles à découvrir : découvre les astuces pour ne plus jamais les oublier et entraîne-toi sur ces
									règles pour être performant à l’écrit.
								</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				<v-col md="4" sm="12" cols="12"> </v-col>
			</v-row>
		</div>
		<Loading :show="loading_show" label="On commence par la mémoire" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-full-loading';

export default {
	name: 'Default',
	props: {
		dataId: String,
		countStory: Number,
	},
	components: {
		Loading,
	},
	data() {
		return {
			timeoutCircleProgress: true,
			loading_timeOut: 2000,
			goToStories: false,
			loading_show: false,
			showQuestions: false,
		};
	},
	filters: {
		tronc(value) {
			if (value && value.length > 25) {
				return value.slice(0, 20) + '...';
			} else {
				return value;
			}
		},
	},
	mounted() {
		//for mobile : always display top of the page
		window.scrollTo(0, 0);

		//timeOutProgressBar
		setTimeout(() => {
			this.timeoutCircleProgress = false;
		}, this.loading_timeOut);
	},
	methods: {
		goToTrainingQuestion() {
			this.loading_show = true;
			setTimeout(() => {
				this.goToStories = true;
				this.$emit('goToStories', true);
				this.loading_show = false;
			}, this.loading_timeOut);
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-start');
			}
		},
		colorRules(value) {
			if (value == 1) {
				return '#FFD15E';
			} else {
				return '#34e5c8';
			}
		},
	},
	computed: {
		...mapGetters('exercice', {
			lastExerciceDone: 'exerciceDone',
			exerciceInfos: 'exerciceInfos',
			performanceExercice: 'performanceExercice',
			allExerciceFromFormationCategory: 'allExerciceFromFormationCategory',
		}),
	},
};
</script>

<style scoped>
.textPointIcon {
	margin-left: 20px;
}
.color-wait {
	color: gainsboro;
}
.alignChart {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.pointIcon {
	height: 50px;
}
.ortho-ex-index-image-container {
	position: relative;
	z-index: 10;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.ortho-ex-index-image {
	max-width: 70%;
	border-radius: 5px;
}
.border-section-score:not(:last-child) {
	border-bottom: 1px solid gainsboro;
}

@media only screen and (max-width: 600px) {
	.textPointIcon {
		margin-left: initial;
	}
}
</style>
